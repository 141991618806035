import React, { useState, useEffect } from "react";

const Mobile = () => {
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width:500px)");
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  useEffect(() => {
    if (isMobile) {
      setShowModal(true);
    }
  }, [isMobile]);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-slate-900 bg-opacity-75">
          <div className="bg-slate-900 rounded-lg p-8 max-w-md w-full sm:w-auto mx-5">
            <h2 className="text-2xl font-bold mb-4">View on Desktop</h2>
            <p className="mb-4">
              For a better experience, please view this website on a desktop
              device.
            </p>
            <button
              className="px-4 py-2 bg-violet-900 hover:bg-violet-950 text-white rounded"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Mobile;
