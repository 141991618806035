import React, { useState, useEffect } from "react";
import { BallCanvas } from "./canvas";
import { SectionWrapper } from "../hoc";
import { technologies } from "../constants";

const Tech = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width:500px)");
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  const filteredTechnologies = technologies.filter((technology) => {
    return ![
      "mui",
      "bootstrap",
      "git",
      "Redux Toolkit",
      "MongoDB",
      "CSS3",
      "Express JS",
    ].includes(technology.name);
  });
  const technologiesToMap = isMobile ? filteredTechnologies : technologies;
  return (
    <div className="flex flex-row flex-wrap justify-center gap-10 ">
      {technologiesToMap.map((technology) => (
        <div className="w-28 h-28" key={technology.name}>
          <BallCanvas icon={technology.icon} />
        </div>
      ))}
    </div>
  );
};

export default SectionWrapper(Tech, "");
